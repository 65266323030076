import React, { useState, useContext, useEffect, useRef } from "react";
import Context from "../Context/Context";
import "./Gallery.css";
import { Upload } from "lucide-react";
import NavBar from "../Components/NavBar";
import fileUpload from "../common/utils/upload-file";
import { API, Storage } from "aws-amplify";
import { Image, Trash2Icon, X } from "lucide-react";
import { FadeLoader } from "react-spinners";
import { MdEdit } from "react-icons/md";
import { Modal } from "flowbite-react";

const Gallery = () => {
  const [uploading, setUploading] = useState(false);
  const {
    getImagesFromAPI,
    tempImgSrc,
    setTempImgSrc,
    imageUrls,
    setImageUrls,
    title,
    setTitle,
    description,
    setDescription,
  } = useContext(Context);

  const [img, setImg] = useState();
  const [model, setModel] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const userData = useContext(Context);
  const user = userData.userData;
  const { setLoader } = userData.util;
  const [selectedFile, setSelectedFile] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const bucketName = "institution-utils";
  const region = "us-east-2";
  const institution = user.institution;
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [isDeleteing, setIsDeleteing] = useState(false);
  const [isDataUploading, setIsDataUploading] = useState(false);
  const [selectValve, setSelectValve] = useState("All");
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getImagesFromAPI();
      setLoading(false);
    };
    console.log("Fetching images...");
    console.log("User data:", user);
    if (user && user.userType === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    console.log("isAdmin after setting:", isAdmin);
    fetchData();
  }, [user]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log("File selected:", file);

    if (file.size > 25 * 1024 * 1024) {
      alert("Please upload an image/video of less than 25MB size.");
      return;
    }

    const folderPath = `${user.institution}`;
    const existingImageNames = imageUrls.map((url) => url.split("/").pop());

    if (existingImageNames.includes(file.name)) {
      alert("Selected image/video already exists.");
      return (fileInputRef.current.value = "");
    }

    setShowInput(true);
    if (imageUrls.length >= 25) {
      alert("Maximum limit reached. You can upload up to 25 images/videos.");
      return;
    }

    setUploading(true);
    try {
      console.log("Uploading media:", file.name, file.type);

      const imageUrl = await fileUpload({
        bucket: bucketName,
        region: region,
        folder: folderPath,
        file: file,
      });

      console.log("Image uploaded successfully.", imageUrl);

      setSelectedFile(imageUrl);
      console.log(title);
      console.log(description);
      console.log("API call successful.");
      await getImagesFromAPI();
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error uploading image: ", error);
      alert("Failed to upload image");
    } finally {
      setUploading(false); // Ensure this is called to stop the spinner
    }
  };

  const uploadImageDataToAPI = async () => {
    setIsDataUploading(true);
    const filename = selectedFile.split("/").pop();
    try {
      const data = {
        institution: institution,
        title: title,
        description: description,
        imageUrl: filename,
        imgLink: selectedFile,
      };

      await API.post("user", `/admin/upload-gallery-file/${institution}`, {
        body: data,
      });
      setTitle("");
      setDescription("");
      setSelectedFile(""); // Reset the selected file
      setIsDataUploading(false);
      setShowInput(false);
    } catch (error) {
      console.error("Error uploading image data to API: ", error);
    } finally {
      getImagesFromAPI();
      setTempImgSrc("");
    }
  };

  const getImg = (imageUrl) => {
    setTempImgSrc(imageUrl);
    setModel(true);
    setImg(imageUrl);
    getImagesFromAPI(imageUrl);
  };

  const handleDelete = async (tempImgSrc) => {
    console.log("Deleting image:", tempImgSrc);
    try {
      const key = img.split(
        `https://${bucketName}.s3.${region}.amazonaws.com/public/`
      )[1];

      await Storage.remove(key, {
        bucket: bucketName,
        region: region,
      });
      setImageUrls((prevUrls) => prevUrls.filter((url) => url !== tempImgSrc));
      setSelectedFile("");
      // Fetch updated image list after deletion
      getImagesFromAPI();
    } catch (error) {
      console.error("Error deleting image: ", error);
    }
    setModel(false);
  };

  const dataDelete = async (tempImgSrc) => {
    console.log(tempImgSrc);
    setIsDeleteing(true);
    try {
      await API.del("user", `/admin/delete-gallery-file/${institution}`, {
        body: {
          imageUrl: tempImgSrc,
        },
      });
    } catch (error) {
      console.log(error);
    }
    handleDelete(tempImgSrc);
    setIsDeleteing(false);
    getImagesFromAPI();
    setModel(false);
  };

  const dataEdit = async (event) => {
    event.preventDefault();

    setLoader(true);
    try {
      await API.put("user", `/admin/update-gallery-file/${institution}`, {
        body: {
          title,
          description,
          imageUrl: tempImgSrc,
        },
      });
      setOpenEditModal(false);
      alert("Message edited successfully.");
    } catch (e) {
      console.log(e);
      alert(e.message);
    } finally {
      setLoader(false);
    }
  };

  const handleCancelUpload = () => {
    setShowInput(false);
    setSelectedFile("");
    setTitle("");
    setDescription("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  let mediaUrls = imageUrls;
  if (selectValve === "images") {
    mediaUrls = imageUrls.filter(
      (url) =>
        url.split("/").pop().split(".")[1] !== "mp4" &&
        url.split("/").pop().split(".")[1] !== "avi"
    );
  } else if (selectValve === "videos") {
    mediaUrls = imageUrls.filter(
      (url) =>
        url.split("/").pop().split(".")[1] === "mp4" ||
        url.split("/").pop().split(".")[1] === "avi"
    );
  } else {
    mediaUrls = imageUrls;
  }

  return (
    <>
      <div className="min-h-screen pb-[8rem] gallery-container">
        {<NavBar />}

        <div className={model ? "model open" : "model"}>
          {isDeleteing ? (
            <div className="w-[22.5vw] h-[30vh] z-10 flex flex-col justify-center items-center rounded-lg max800:w-[70vw] max800:h-[20vh]">
              <FadeLoader
                color="white"
                speedMultiplier={2}
                height={15}
                width={5}
              />
            </div>
          ) : (
            <>
              {tempImgSrc?.split("/").pop().split(".")[1] === "mp4" ||
              tempImgSrc?.split("/").pop().split(".")[1] === "avi" ? (
                <div className="max-h-[90vh] relative">
                  <video
                    className="w-auto max-w-full h-auto max-h-[90vh] block box-border my-0 mx-auto rounded"
                    src={tempImgSrc}
                    autoPlay
                    muted
                    controls
                    loop
                  />
                  {(title || description) && (
                    <div className="absolute bottom-6 left-8 right-8 p-4 bg-zinc-800 bg-opacity-50 text-white w-auto min-w-[20rem] min-h-[20vh] max-w-[30rem] rounded-lg max800:w-[90vw] max800:fixed max800:bottom-4 max800:text-[.7rem]">
                      <h2 className="text-xl font-semibold roboto-slab-regural">
                        {title}
                      </h2>
                      <p className="roboto-slab-normal">{description}</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="max-h-[90vh] relative">
                  <img
                    className="w-auto max-w-[80vw] h-auto max-h-[90vh] block box-border my-0 mx-auto rounded"
                    src={tempImgSrc}
                    alt="fitness"
                  />
                  {(title || description) && (
                    <div className="absolute bottom-6 left-8 right-8 p-4 bg-zinc-800 bg-opacity-50 text-white w-auto max-w-[30rem] rounded-lg max800:w-[90vw] max800:fixed max800:bottom-4 max800:text-[.7rem]">
                      <h2 className="text-xl font-semibold roboto-slab-regural">
                        {title}
                      </h2>
                      <p className="roboto-slab-normal">{description}</p>
                    </div>
                  )}
                </div>
              )}
              <div className="fixed top-[20px] right-[20px] flex flex-row justify-center items-center gap-3 text-white cursor-pointer">
                {isAdmin && (
                  <Trash2Icon
                    height={24}
                    width={24}
                    onClick={() => dataDelete(tempImgSrc)}
                  />
                )}
                {isAdmin && (
                  <MdEdit
                    size={24}
                    onClick={() => {
                      setModel(false);
                      setOpenEditModal(true);
                    }}
                  />
                )}
                {isAdmin && (
                  <Modal
                    className="z-50"
                    show={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <form onSubmit={dataEdit} className="flex flex-col gap-2">
                        {/* <input
                        type="file"
                          name="imageUrl"
                          value={tempImgSrc}
                          onChange={(e) => setTempImgSrc(e.target.value)}
                          hidden
                          required
                        /> */}
                        <input
                          type="text"
                          name="title"
                          placeholder="Title"
                          defaultValue={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                        <textarea
                          name="description"
                          placeholder="Description"
                          defaultValue={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        />
                        <div className="grid grid-cols-2 gap-2">
                          <button
                            className="bg-green-500 text-white rounded-lg px-4 py-2"
                            type="submit"
                            onClick={dataEdit}
                          >
                            Save Changes
                          </button>
                          <button
                            type="button"
                            className="bg-red-500 text-white rounded-lg px-4 py-2"
                            onClick={() => setOpenEditModal(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal>
                )}
                <X
                  height={24}
                  width={24}
                  onClick={() => {
                    setModel(false);
                    setTitle("");
                    setDescription("");
                    setTempImgSrc("");
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className={showInput ? "showInput open" : "showInput"}>
          {isDataUploading ? (
            <div className="w-[22.5vw] h-[30vh] z-10 flex flex-col justify-center items-center rounded-lg max800:w-[70vw] max800:h-[20vh]">
              <FadeLoader
                color="white"
                speedMultiplier={2}
                height={15}
                width={5}
              />
            </div>
          ) : (
            <>
              <div className="h-auto w-[40vw] bg-white px-5 py-4 rounded-md flex flex-col justify-center items-center gap-4 max800:w-[90vw]">
                <div className="h-[30vh] max800:h-[20vh]">
                  {selectedFile ? (
                    selectedFile?.split("/").pop().split(".")[1] === "mp4" ||
                    selectedFile?.split("/").pop().split(".")[1] === "avi" ? (
                      <video
                        className="w-[22.5vw] h-[30vh] rounded"
                        src={selectedFile}
                        autoPlay
                        muted
                      />
                    ) : (
                      <img
                        className="w-[22.5vw] h-[30vh] rounded object-cover"
                        style={{
                          border: `2px solid #FDCF08`,
                        }}
                        src={selectedFile}
                        alt="fitness"
                      />
                    )
                  ) : uploading ? (
                    <div
                      className="w-[22.5vw] h-[30vh] z-10 flex flex-col justify-center items-center bg-[#ffffff6e] rounded-lg max800:w-[70vw] max800:h-[20vh]"
                      style={{
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        backdropFilter: "blur(5px)",
                      }}
                    >
                      {/* <Spinner /> */}
                    </div>
                  ) : (
                    <label
                      className="upload border-2 border-dotted border-stone-500 rounded-lg cursor-pointer flex text-black text-[1.2rem] font-semibold flex-col justify-center items-center w-[22.5vw] h-[30vh] mb-1 mt-1  max800:w-[70vw] max800:h-[20vh]"
                      style={{
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        backdropFilter: "blur(5px)",
                      }}
                    >
                      <Upload height={20} width={20} />
                      Upload
                      <input
                        type="file"
                        className="hidden"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </label>
                  )}
                </div>
                <div className="w-full">
                  <div className="mb-4">
                    <label
                      for="title"
                      className="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={title}
                      placeholder="Enter the title"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      for="description"
                      className="block text-gray-700 text-sm font-semibold mb-2"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      placeholder="Enter the description"
                      value={description}
                      rows="4"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="min800:w-full flex justify-between max800:justify-between max950:gap-2 ">
                  <button
                    className="px-12 py-2 text-white bg-red-400 rounded flex flex-row gap-2 items-center justify-center"
                    onClick={() => {
                      handleCancelUpload();
                      setUploading(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-12 py-2 text-white rounded flex flex-row gap-2 items-center justify-center"
                    style={{
                      backgroundColor: `#FDCF08`,
                    }}
                    onClick={() => {
                      setShowInput(false);
                      uploadImageDataToAPI(selectedFile);
                    }}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="flex justify-end items-center gap-3 mt-[4rem] mr-8 mb-4">
          <select
            className="px-[1.3rem] py-2 border-2 rounded cursor-pointer"
            value={selectValve}
            onChange={(e) => setSelectValve(e.target.value)}
          >
            <option value="All">All</option>
            <option value="images">Images</option>
            <option value="videos">Videos</option>
          </select>
          {isAdmin && (
            <button
              className="px-12 py-2 text-white bg-yellow-400 border-2 border-white rounded flex flex-row gap-2 items-center justify-center"
              onClick={() => {
                setShowInput(true);
              }}
            >
              <Upload height={20} width={20} />
              Upload
            </button>
          )}
        </div>

        {mediaUrls.length === 0 ? (
          <h2 className="text-center text-[1.5rem] mt-16 text-slate-400">
            No Media Found
          </h2>
        ) : (
          <div className="gallery mb-4 columns-4 max850:columns-2">
            {mediaUrls.map((imageUrl, index) =>
              loading ? (
                //   <SkeletonTheme baseColor="#bcbcbc" highlightColor="#a6a6a6">
                //     <div className="relative flex justify-center items-center">
                //       <Skeleton
                //         height="40vh"
                //         width="24vw"
                //         className="mb-[12px] max900:!w-[45vw] max900:!h-[20vh]"
                //       />
                //       <Image
                //         className="absolute w-10 top-[45%] opacity-50"
                //         height={48}
                //         width={48}
                //       />
                //     </div>
                //   </SkeletonTheme>
                <></>
              ) : (
                <div className="media" key={index}>
                  {imageUrl.split("/").pop().split(".")[1] === "mp4" ||
                  imageUrl.split("/").pop().split(".")[1] === "avi" ? (
                    <video
                      key={index}
                      src={imageUrl}
                      alt="Gallery"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => getImg(imageUrl)}
                      autoPlay
                      muted
                      loop
                    />
                  ) : (
                    <img
                      key={index}
                      src={imageUrl}
                      alt="Gallery"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => getImg(imageUrl)}
                    />
                  )}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
